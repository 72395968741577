import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, RadioList2 } from "uikit";
import { isUndefined, isBoolean } from "lodash";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { useTypedSelector } from "../../../../../../../../redux/store";
import LabeledBlock from "../../../../../../../../components/LabeledBlock";
import Divider from "../../../../../../../../components/Divider";

import Root from "./components/Root";

export enum CloseReason {
	Completed = "executed",
	ClientCanceled = "declined_by_client",
	ExecutorCanceled = "executor_mistake",
	DispatcherMistake = "operator_mistake",
	NoCar = "car_not_found",
	Miscalculate = "tariff_calculation",
}

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const transfer = useMemo(
		() => activeOrder?.additionalFields?.transfer,
		[activeOrder],
	);

	const lastReceivedOrder = useMemo(
		() => transfer?.lastReceivedOrder,
		[transfer?.lastReceivedOrder],
	);

	const hasActiveOrderExecutorFromTransfer = useMemo(() => {
		if (isUndefined(lastReceivedOrder)) return false;
		return !lastReceivedOrder?.executorToOrder?.length;
	}, [lastReceivedOrder]);

	const hasActiveOrderExecutor = useMemo(() => {
		if (isUndefined(activeOrder)) return false;

		return !activeOrder?.executorToOrder?.length;
	}, [activeOrder]);

	const isOwn = useMemo(() => {
		if (isUndefined(activeOrder)) return false;
		const { isOwn } = activeOrder;
		return isBoolean(isOwn) ? isOwn : true;
	}, [activeOrder]);

	const reasonValue = valueEditor.useGetter("reason");
	const reasonOnChange = valueEditor.useSetter("reason");

	const addClientToBlackListValue = valueEditor.useGetter(
		"addClientToBlackList",
	);
	const addClientToBlackListOnChange = valueEditor.useSetter(
		"addClientToBlackList",
	);

	const options = useMemo<RadioList2.Option<CloseReason>[]>(
		() => [
			{
				key: CloseReason.Completed,
				value: CloseReason.Completed,
				disabled: !hasActiveOrderExecutor,
				content:
					t(
						"pages.mainPage.pages.orders.closeOrderModal.content.str200",
					) ?? "",
			},
			{
				key: CloseReason.ClientCanceled,
				value: CloseReason.ClientCanceled,
				content:
					t(
						"pages.mainPage.pages.orders.closeOrderModal.content.str201",
					) ?? "",
			},
			{
				key: CloseReason.ExecutorCanceled,
				value: CloseReason.ExecutorCanceled,
				disabled: !hasActiveOrderExecutor || !isOwn,
				content:
					t(
						"pages.mainPage.pages.orders.closeOrderModal.content.str202",
					) ?? "",
			},
			{
				key: CloseReason.DispatcherMistake,
				value: CloseReason.DispatcherMistake,
				disabled: !isOwn,
				content:
					t(
						"pages.mainPage.pages.orders.closeOrderModal.content.str203",
					) ?? "",
			},
			{
				key: CloseReason.NoCar,
				value: CloseReason.NoCar,
				disabled: hasActiveOrderExecutor || !isOwn,
				content:
					t(
						"pages.mainPage.pages.orders.closeOrderModal.content.str204",
					) ?? "",
			},
			{
				key: CloseReason.Miscalculate,
				value: CloseReason.Miscalculate,
				disabled: hasActiveOrderExecutor || !isOwn,
				content:
					t(
						"pages.mainPage.pages.orders.closeOrderModal.content.str205",
					) ?? "",
			},
		],
		[hasActiveOrderExecutor, isOwn, t],
	);

	return (
		<Root gaps="16px*">
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.closeOrderModal.content.str0",
					) ?? ""
				}
			>
				<RadioList2
					value={reasonValue}
					autoFocus
					options={options}
					onChange={reasonOnChange}
				>
					{(radios) => <Column gaps="8px*">{radios}</Column>}
				</RadioList2>
			</LabeledBlock>
			<Divider orientation="horizontal" size="max" color="#DEE0E2" />
			<CheckBoxWithContent
				value={addClientToBlackListValue}
				onChange={addClientToBlackListOnChange}
				gap="10px"
			>
				{t(
					"pages.mainPage.pages.orders.closeOrderModal.content.str150",
				) ?? ""}
			</CheckBoxWithContent>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		reason?: CloseReason;
		addClientToBlackList: boolean;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
